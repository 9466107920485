import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Burger from "../images/burger3.jpg"
import Limonade from "../images/lemonade3.jpg"
import FoodTruck from "../images/food-truck2.jpg"
import Fries1 from "../images/fries1.png"
import Fries2 from "../images/fries2.png"
import Fries3 from "../images/fries3.png"
import Fries5 from "../images/fries5.png"
import Vegan from "../images/vegan.png"
import Helmet from "react-helmet"
import Nacho from "../images/nacho_hell.jpg"

import RelativeTimeFormat from "relative-time-format"
import de from "relative-time-format/locale/de.json"

import Standorte from "../standorte.json"

const today = new Date().toISOString().substr(0, 10)

const IndexPage = () => {
  const hasDates =
    Standorte.filter(
      standort =>
        new Date(standort.datum) >=
        new Date(new Date().toISOString().substr(0, 10))
    ).length > 0
  const standortTabelle = (short = false) => {
    RelativeTimeFormat.addLocale(de)
    const intlRtf = (Intl && Intl.RelativeTimeFormat) || RelativeTimeFormat
    const rtf = new intlRtf("de", { numeric: "auto" })
    return Standorte.filter(
      standort => new Date(standort.datum) >= new Date(today)
    )
      .slice(0, 5)
      .map((standort, index) => (
        <li
          id={"standort" + index}
          key={"standort" + index}
          className={standort.datum === today ? "active" : ""}
        >
          <div>
            <span>
              {rtf.format(
                Math.floor(
                  (new Date(standort.datum) - new Date(today)) / 86400000
                ),
                "day"
              )}{" "}
              (
              {new Intl.DateTimeFormat("default", {
                // dateStyle: short ? "long" : "full",
                weekday: "short",
                year: short ? "2-digit" : "numeric",
                month: short ? "short" : "long",
                day: "numeric",
              }).format(new Date(standort.datum))}
              {standort.zeit && ` von ${standort.zeit}`})
            </span>
            <br />
            {standort.zeit ? (
              <>
                <a
                  rel="noopener noreferrer nofollow"
                  href={`https://www.google.com/maps/search/${decodeURIComponent(
                    standort.text
                  )}?hl=de&source=opensearch`}
                  target="_blank"
                >
                  {standort.text}
                </a>
                {standort.bemerkung && index < 3 && (
                  <>
                    <br />
                    <b
                      style={{
                        whiteSpace: "pre-line",
                        boxDecorationBreak: "clone",
                      }}
                      className="bg-danger box-decoration-clone px-1 text-white"
                    >
                      {standort.bemerkung}
                    </b>
                  </>
                )}
              </>
            ) : (
              standort.text
            )}
          </div>
        </li>
      ))
  }

  const preOrder = () => (
    <div className="window-footer">
      Vorbestellen:{" "}
      <a
        rel="noopener noreferrer nofollow"
        target="_blank"
        href="tel:+436606933305"
        className="d-inline-flex align-items-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
        </svg>
        0660 69 333 05
      </a>{" "}
      oder{" "}
      <a
        rel="noopener noreferrer nofollow"
        target="_blank"
        href="https://wa.me/436606933305"
        className="d-inline-flex align-items-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M3 21l1.65 -3.8a9 9 0 1 1 3.4 2.9l-5.05 .9" />
          <path d="M9 10a.5 .5 0 0 0 1 0v-1a.5 .5 0 0 0 -1 0v1a5 5 0 0 0 5 5h1a.5 .5 0 0 0 0 -1h-1a.5 .5 0 0 0 0 1" />
        </svg>
        Whatsapp
      </a>
    </div>
  );

  return (
    <Layout>
      <SEO title="Foodtruck • Burger • Limonaden • Eistees" />
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(
            Standorte.filter(
              standort =>
                new Date(standort.datum) >= new Date(today) &&
                /^[\d]{2}:[\d]{2} bis [\d]{2}:[\d]{2}/.test(
                  standort.zeit?.trim() || ""
                )
            )
              .slice(0, 10)
              .map((standort, index) => {
                const [von, bis] = standort.zeit.trim().split(" bis ")
                return {
                  "@context": "https://schema.org",
                  "@type": "Event",
                  eventAttendanceMode:
                    "https://schema.org/OfflineEventAttendanceMode",
                  eventStatus: "https://schema.org/EventScheduled",
                  startDate: `${standort.datum}T${von}+02:00`,
                  endDate: `${standort.datum}T${bis}+02:00`,
                  organizer: {
                    "@type": "Organization",
                    name: "Helmart's Foodtruck",
                  },
                  location: {
                    "@type": "Place",
                    name: standort.text + ", Österreich",
                  },
                }
              })
          )}
        </script>
      </Helmet>

      <div
        className="site-blocks-cover inner-page overlayx"
        style={{ backgroundImage: `url(${Nacho})` }} // FoodTruck
      >
        <div
          data-nosnippet
          className="row align-items-center justify-content-center"
        >
          <div className="col-md-4 position-static">
            <div className="h1 text-uppercase position-absolute mt-5 rosti shadow-lg">
              <span className="h5">NEU</span>
              <br />
              <span>Nacho Hell</span>
              <br />
              <div className="rosti-desc">
                Rindfleisch Patty • Sauerteig-Bun • 
                Jalapenos-Chili-Mayonnaise • Nachos • frische Salsa • 
                Eisbergsalat • Tomaten • Zwiebel • Speck • Cheddar-Cheese
              </div>
            </div>
          </div>
          <div className={"col-md-6 window-col " + (hasDates ? "" : "d-none")}>
            <div className="window d-none d-md-block">
              <div className="window-title">Unsere nächsten Standorte</div>
              <ul>{standortTabelle(false)}</ul>
              {preOrder()}
            </div>
          </div>
        </div>
      </div>

      <div className="yellow-lines"></div>
      <img src={Fries1} className="fries1" alt="Pommes" />
      <img src={Fries2} className="fries2" alt="Pommes" />
      <img src={Fries3} className="fries3" alt="Pommes" />
      <img src={Fries5} className="fries5" alt="Pommes" />

      <div
        data-nosnippet
        className={"window d-md-none my-5 mx-4 " + (hasDates ? "" : "d-none")}
      >
        <div className="window-title">Unsere nächsten Standorte</div>
        <ul>{standortTabelle(true)}</ul>
        {preOrder()}
      </div>

      <div className="site-section site-section-sm first-section">
        <div className="container">
          <div className="row mb-5">
            <div className="col-md-12 text-center">
              <h1 className="caption d-block mb-2 font-secondary font-weight-bold text-secondary">
                Burger Food Truck für Wien Umgebung
              </h1>
              <h2 className="site-section-heading text-uppercase text-center font-secondary px-5 px-md-2">
                Warum wir unsere Burger und Limonaden lieben
              </h2>
            </div>
          </div>
          <div className="row border-responsive">
            <div className="col-md-6 col-lg-3 mb-4 mb-lg-0 border-right px-5 px-md-2">
              <div className="text-center">
                <div className="text-uppercase h4 mb-3">Regional</div>
                <p>
                  Helmart's Burger bestehen aus 100% österreichischem
                  Rindfleisch. Unsere Burger-Buns bestehen aus feinstem
                  Sauerteig.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 mb-4 mb-lg-0 border-right px-5 px-md-2">
              <div className="text-center">
                <div className="text-uppercase h4 mb-3">Natürlich</div>
                <p>
                  Unsere Burger und Limonaden bestehen zu 100% aus natürlichen
                  Zutaten. Zusatzstoffe, Zitronensäure oder Farbstoffe haben bei
                  uns keinen Platz.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 mb-4 mb-lg-0 border-right px-5 px-md-2">
              <div className="text-center">
                <div className="text-uppercase h4 mb-3">Frisch</div>
                <p>
                  Unsere Speisen werden frisch und mit viel Liebe zubereitet. Um
                  die Frische zu bewahren, wird ein Großteil der Zutaten noch am
                  selben Tag eingekauft und verarbeitet.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 mb-4 mb-lg-0 px-5 px-md-2">
              <div className="text-center">
                <div className="text-uppercase h4 mb-3">Selbstgemacht</div>
                <p>
                  Helmart's bereitet für Ihre Gäste frisch vor Ort bestes
                  Premium Streetfood zu. Dafür verwenden wir nur die besten
                  Zutaten, die unseren Qualitätsansprüchen entsprechen.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="site-half">
        <div
          className="img-bg-1"
          style={{ backgroundImage: `url(${Burger})` }}
        ></div>
        <div className="container">
          <div className="row no-gutters align-items-stretch">
            <div className="col-lg-5 ml-lg-auto py-5 py-5 px-5 px-md-0">
              <span className="caption d-block mb-2 font-secondary font-weight-bold">
                100% österreichisches Rindfleisch
              </span>
              <h2 className="site-section-heading text-uppercase font-secondary mb-5">
                Burger
              </h2>
              <p>
                Unsere Burger werden medium gegrillt, bei Bedarf auch rare oder
                well done. Die schmackhaften Sauerteig Burger Buns sind schön
                weich und fluffig, aber gleichzeitig fest genug um Saucen und
                Fleisch aufzunehmen. Dazu servieren wir Pommes oder
                Süßkartoffeln zusammen mit unseren hausgemachten Dips.
              </p>

              <p>
                Dass ein Burger nicht immer Fleisch enthalten muss um gut zu
                schmecken, beweist unsere vegane Variante "Vegan Soul".
                <img
                  src={Vegan}
                  alt="Vegan"
                  className="position-absolute ml-1"
                />
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="site-half block mb-5">
        <div
          className="img-bg-1 right"
          style={{ backgroundImage: `url(${Limonade})` }}
        ></div>
        <div className="container">
          <div className="row no-gutters align-items-stretch">
            <div className="col-lg-5 mr-lg-auto py-5 px-5 px-md-0">
              <span className="caption d-block mb-2 font-secondary font-weight-bold">
                Erfrischend natürlich
              </span>
              <h2 className="site-section-heading text-uppercase font-secondary mb-5">
                Limonaden und Eistees
              </h2>
              <p>
                Helmart's hausgemachte Limonaden und Eistees werden schonend und
                nur mit frischen Zutaten zubereitet. Wir lieben es, verschiedene
                Geschmacksrichtungen zu kombinieren. Unser Angebot an Limonaden
                und Eistees variiert somit regelmäßig. Ingwer/Limette/Minze,
                Himbeer/Zitrone oder Zitrone/Grüner Tee gehören zu unseren
                beliebtesten Drinks.
              </p>

              <p>
                So wie unsere Burger werden auch unsere Getränke in
                umweltfreundlichen und kompostierbaren Verpackungen serviert.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
